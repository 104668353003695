<template>
    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="No posting has been submitted" @click:row="(any, { item }) => showBoard(item)" class="v-data-table--default tbody-tr-cursor-pointer">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #footer>
            <pagination-component v-bind="{ count }" :value="page" @change="changePage" />

            <div v-show="false" class="board-bottom">
                <div class="v-btn--group justify-end">
                    <v-btn @click="$emit('loadmore')" large color="secondary">{{ $t("common.list") }}</v-btn>
                    <v-btn @click="$router.push('?mode=input')" large color="primary">Write</v-btn>
                </div>
                <!-- <div class="v-btn--group justify-end">
                    <v-btn @click="$router.push(`${$route.path}/write`)" large color="primary">Write</v-btn>
                </div> -->
            </div>
        </template>
    </v-data-table>
</template>

<script>
import PaginationComponent from "@/components/client/control/pagination-component.vue";
const categories = [];
const headers = [
    { align: "center", text: "Title", value: "subject", cellClass: "text-align-left grey-6--text" },
    { width: 160, align: "center", text: "Views", value: "viewCount" },
    { width: 160, align: "center", text: "Writer", value: "writer", formatter: (value) => value?.name || "-" },
    { width: 160, align: "center", text: "Date", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
];

export default {
    components: {
        PaginationComponent,
    },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    data: () => ({
        headers,
        categories: [],
    }),
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        items() {
            return this.boards.map((board, index) => ({ ...board, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
};
</script>

<style scoped>
</style>
